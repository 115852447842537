<template>
  <div class="grid">
    <div class="col-12">
      <Toast />
      <div class="card">
        <h5>Nova Permissão</h5>
        <form @submit.prevent="validate()">
          <div class="p-fluid formgrid grid">
            <div class="field col-6">
              <label for="nome">Nome</label>
              <InputText
                id="nome"
                v-model="permissao.nome"
                :class="{
                  'p-invalid': submitted && v$.permissao.nome.$invalid,
                }"
                type="text" />
              <div
                v-if="submitted && v$.permissao.nome.required.$invalid"
                class="p-error">
                O campo Nome é obrigatório.
              </div>
            </div>
          </div>
          <Button type="submit" label="Salvar" class="md:col-2"></Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Permissao from '@/domain/Permissao.js'
import PermissaoService from '@/service/PermissaoService.js'

export default {
  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      permissao: new Permissao(),
      id: this.$route.params.id,
      submitted: false,
      submitStatus: null,
    }
  },

  validations() {
    return {
      permissao: {
        nome: { required },
      },
    }
  },

  created() {
    this.service = new PermissaoService(this.$http)
    if (this.id) {
      this.service.getPermissao(this.id).then((res) => {
        this.permissao = res
      })
    }
  },

  methods: {
    validate() {
      this.submitted = true
      this.v$.permissao.$touch()

      if (this.v$.permissao.$invalid) {
        this.submitStatus = 'error'
        return
      } else {
        this.submitStatus = 'PENDING'
        this.salvar()

        setTimeout(() => {
          this.submitStatus = 'OK'
        }, 500)
      }
    },

    salvar() {
      this.permissao.alteradoPor = this.alteradoPor
      this.permissao.cadastradoPor = this.cadastradoPor
      this.service.savePermissao(this.permissao).then(
        () => {
          this.$router.push({ name: 'permissoes' })
        },
        (err) => {
          console.log(err)
        },
      )
    },

    exibeToast() {
      this.$toast.add({
        severity: 'success',
        summary: 'Atualizado com sucesso!',
        life: 10000,
      })
    },
  },
}
</script>

<style></style>
